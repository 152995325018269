import {
    Grid,
    Box,
    Image,
    Heading,
    Text,
    Highlight
} from '@chakra-ui/react';
import course_image_1 from './img/course_1.png';
import course_image_2 from './img/course_2.png';
import course_image_3 from './img/course_3.png';
import course_image_4 from './img/course_4.png';
import './App.css';

// const breakpoints = ['0em', <small> '30em', <mobile> '48em', <laptop> '62em', <desktop> '80em', <bigscreen> '96em']
//  [small, mobile, laptop, desktop, bigscreen]

const courseDatas = [
  {id: 1, title: 'ACCA F1 to F4', description: '180+ hours Association of Chartered Certified Accountants Hybrid Online Lectures.', image: course_image_4},
  {id: 2, title: 'CA Foundation', description: '400+ Hours Chartered Accountancy Foundation Hybrid Online Lectures.', image: course_image_1},
  {id: 3, title: 'CMA Foundation', description: '190+ Hours Cost and Management Accountant Hybrid Online Lectures.', image: course_image_2},
  {id: 4, title: 'CSEET', description: '140+ Hours CS Executive Entrance Test Hybrid Online Lectures.', image: course_image_3},
]

function CourseCard({course, description, image}) {
    return (
        <Box 
          bgColor='white' 
          p={5} 
          textAlign='center'
          borderRadius='1rem'
        >
            <Image w={['200px', '250px']} m='auto' src={image} alt='' />
            <Heading size='md' mb={2}>{course}</Heading>
            <Text>{description}</Text>
        </Box>
    )
}

export default function Courses() {

  let courseCardList = []

  courseDatas.forEach((courseData) => {
    courseCardList.push(
      <CourseCard key={courseData.id} course={courseData.title} description={courseData.description} image={courseData.image}/>
    )
  })

  return (
    <Box bg='linear-gradient(to right, #F83D5C, #FD4B2F)' minH={['100%', '100%', '100vh']} display='flex' alignItems='center'>
      <Box className='container' py={[5, 5, 5, 0]} px={[ 0, 0, 10, 0]}>
        <Heading color='white' size='3xl' textAlign='center'>Our Courses</Heading>
        <Grid 
          gap={5} 
          gridAutoFlow={['row', 'row', 'row', 'column']} // Responsive
          gridAutoColumns='1fr'
          py={['1.5rem', '2rem', '2rem', '2rem']} // Responsive
        >
          {courseCardList}
        </Grid>
        <Heading color='white' textAlign='center'>
          <Highlight 
            query='15% discount' 
            styles={{px: '2', py: '1', color: 'white', textDecoration: 'underline', textUnderlineOffset: '.4rem'}}
          >
            Get Up to 15% discount on fees.
          </Highlight>
        </Heading>
        <Heading color='white' textAlign='center'>Start your 10 Days free trial TODAY!</Heading>
      </Box>
    </Box>
  )
}