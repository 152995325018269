import React from 'react';
import { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Grid,
  Button,
  Heading,
  Highlight,
  Input,
  theme,
  Select,
  Text,
  Image,
  Link
} from '@chakra-ui/react';
import Courses from './Section2';
import Section3 from './Section3';
import Footer from './Footer';
import logo from './img/logo.png'
import './App.css';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaGooglePlay, FaAppStoreIos } from 'react-icons/fa';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input'
import { parsePhoneNumber } from 'react-phone-number-input';
import swal from 'sweetalert';

function Header() {
  return (
    <Box className='container' py={5}>
      <Flex align='center' justify='space-between'>
        <Image htmlWidth='160px' src={logo} alt='Lakshya Logo' />
        <Link href='tel:+919061277777'>
          <Button leftIcon={<FaPhoneAlt />} bgColor='#F83D5C' colorScheme='red' color='white' size={['md', 'md', 'lg']} boxShadow='md'>
            +91 9061277777
          </Button>
        </Link>
      </Flex>
    </Box>
  )
}

function RegistrationForm() {

  const [value, setValue] = useState()

  function isValidEmail(email) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  function phoneNumberFormatter(number) {
    var countryCC = parsePhoneNumber(number).countryCallingCode;
    var nationalNumber = parsePhoneNumber(number).nationalNumber;
    return `+${countryCC}-${nationalNumber}`
  }

  function handleSubmit(event) {
    event.preventDefault();

    var formData = new FormData(event.target);
    var fullName = formData.get('name');
    var email = formData.get('email');
    var rawPhoneNumber = formData.get('phone');
    var interestedCourse = formData.get('course');

    if (isValidPhoneNumber(rawPhoneNumber) && isValidEmail(email)) {

      swal("Thank you for registering, our career specialists will be getting in touch with you soon.");

      var phoneNumber = phoneNumberFormatter(rawPhoneNumber);
      
      // API Post
      const axios = require('axios').default;
      let payload = {
        'fullName': fullName,
        'email': email,
        'phone': phoneNumber,
        'course': interestedCourse,
        'source': 'Google'
      }
      axios.post('https://j1j9nrn642.execute-api.ap-south-1.amazonaws.com/landingPageLambda', payload)
        .then(function (response) {
          // console.log(response.data);
      })

      event.target.reset();

    } else if (!isValidPhoneNumber(rawPhoneNumber)) {
      swal("Invalid Number, Try Again!");
    } else if (!isValidEmail(email)) {
      swal("Invalid Email, Try Again!")
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        className='gradient'
        align='center' 
        direction='column'
        gap='1rem' 
        w='100%'
        p={6} 
        borderRadius='1em'
        boxShadow='md'
      >
        <Heading textAlign='center' color='white'>Or Get In Touch with our Experts!</Heading>
        <Input placeholder='Full Name' _placeholder={{ opacity: 1, color: 'white' }} style={{color: 'white'}} name='name' isRequired/>
        <Input placeholder='Email Address' _placeholder={{ opacity: 1, color: 'white' }} style={{color: 'white'}} name='email' type='email' isRequired />
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          placeholder="Enter Phone Number"
          defaultCountry='IN'
          value={value}
          onChange={setValue}
          style={{border: '1px solid white', borderRadius: '5px', padding: '5px', height: '2.5rem', width: '100%'}}
          name='phone'
        />
        <Select placeholder='Interested Course' _placeholder={{ opacity: 1, color: 'white' }} name='course' isRequired>
          <option value='ACCA F1-F4 recorded'>ACCA F1-F4</option>
          <option value='CA Foundation recorded'>CA Foundation</option>
          <option value='CMA Foundation recorded'>CMA Foundation</option>
          <option value='CSEET recorded'>CSEET</option>
        </Select>
        <Button type='submit'>Register</Button>
      </Flex>
    </form>
  )
}

function HeroHeading() {
  return (
    <Box textAlign={['center', 'center', 'center', 'left']}>
      <Heading as='h1' size={['xl', 'xl', 'xl', '3xl']} lineHeight='tall'>
          Learn Unlimited with Lakshya Hybrid Online Classes.
      </Heading>
      <Text as='h3' fontSize={['1.1rem']} py={5}>
        <Highlight
          query='Download the App'
          styles={{ px: '2', py: '1', color: '#F83D5C', textDecoration: 'underline', textUnderlineOffset: '.4rem', fontWeight: 'bold'}}
        >
          Start Learning Now! DOWNLOAD THE APP & Get access to unlimited sessions.
        </Highlight>
      </Text>
      <Flex 
        mx={['auto', 'auto', 'auto', '0']}
        gap={10}
        // border='2px solid #F83D5C'
        // borderRadius='1rem'
        // p={5}
        w='max-content'>
        <Box>
          <Link href='https://play.google.com/store/apps/details?id=com.lakshya.academy'>
            <Button
              size='lg'
              boxShadow='md'
              bgColor='#F83D5C'
              colorScheme='red'
              color='white'
              leftIcon={<FaGooglePlay/>}
            >
              Android
            </Button>
          </Link>
          {/* <Image src={playstore}/>
          <Text>Android</Text> */}
        </Box>
        <Box>
          <Link href='https://apps.apple.com/in/app/lakshya/id1608662481'>
            <Button
              size='lg'
              boxShadow='md'
              bgColor='#F83D5C'
              colorScheme='red'
              color='white'
              leftIcon={<FaAppStoreIos/>}
            >
              iOS
            </Button>
          </Link>
          {/* <Image src={appstore}/>
          <Text>iOS</Text> */}
        </Box>
      </Flex>
    </Box>
  )
}

function Hero() {
  return (
    <Box 
      className='container hero-container' 
      minH={['100vh', '100vh', 'calc(100vh - 450px)', 'calc(100vh - 850px)', 'calc(100vh - 120px)']} 
      display='flex'
      pb={[5, 10, 10, 2]}
    >
      <Box display='flex'>
        <Grid 
          gap={[2, 10, 10, 2]} 
          gridAutoFlow={['row', 'row', 'row', 'column', 'column', 'column']} 
          gridAutoColumns={['1fr', '1fr', '1fr', '65% 35%']} 
          justifyItems={['center', 'center', 'center', 'end']}
          alignItems='center'
          minH={['100vh', '100vh', 'calc(100vh - 450px)', 'calc(100vh - 850px)', 'calc(100vh - 120px)']}
        >
          <HeroHeading/>
          <RegistrationForm/>
        </Grid>
      </Box>
    </Box>
  )
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <div className='custom-background'>
        <Header/>
        <Hero/>
      </div>
      <Courses/>
      <Section3/>
      <Footer/>
    </ChakraProvider>
  );
}

export default App;
